import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  Button, Div,
  FullPageLoading,
  Icon,
  IconButton,
  Input,
  P,
  DashedButton, SmallText } from '@konsys-ui-custom'
import {
  Checkbox as CheckboxAntd,
  Col,
  Drawer,
  Row,
  Select,
} from 'antd'
import { DatePicker } from 'antd4'
import dayjs from 'dayjs'
import _, {
  compact,
  filter,
  find,
  first,
  groupBy,
  isEmpty,
  isNil,
  isNumber, keys,
  last,
  map,
  omit,
  reduce,
  sortBy,
  sumBy,
} from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import client from '../../apollo-client'
import { DELIVERY_CHANNEL, DELIVERY_CHANNEL_PROVIDER, PAYMENT_CHANNEL } from '../../constants/enum'
import { CREATE_QUOTATION, EDIT_QUOTATION } from '../../constants/graph/mutation'
import {
  CUSTOMER_LIST,
  DEFAULT_DELIVERY_CHANNEL,
  GET_PRICE,
  NEXT_QUOTATION_NUMBER,
  ORDER_BONUS_BY_ROLE,
  PROVIDER_LIST,
  QUOTATION_DETAIL, USER_INFO,
  USER_LIST, VARIANT_INFO_FOR_QUOTATION,
} from '../../constants/graph/query'
import { PATH } from '../../constants/path'
import { QUOTATION_STATUS } from '../../constants/status/quotation'
import { GlobalContext } from '../../store/StoreProvider'
import { theme } from '../../styles/variables'
import { getTokenData } from '../../utils/local-data-service'
import { delayInput, showMessageMutation } from '../../utils/util-services'
import { ProductDisplay, ProductForm } from './components'

export const ItemRenderer = ({
  index, items, setItems, variantData, variantLoading, getVariantList, pricingType, item,
  variantInfoData, variantInfoLoading, getVariantInfo,
}) => {
  const [manualPrice, setManualPrice] = useState(false)
  const [search, setSearch] = useState({
    input: '',
    isNewList: false,
  })
  const [getPrice, { data: price }] = useLazyQuery(GET_PRICE)

  useEffect(() => {
    if (pricingType && !_.isNil(item?.id)) {
      getPrice({
        variables: { pricingTypeId: pricingType, packId: item.id },
      })
      setManualPrice(false)
    }
  }, [pricingType, item.id])

  useEffect(() => {
    if (!_.isEmpty(search.input)) {
      setSearch({ ...search, isNewList: true })
    }
  }, [variantData?.variantList?.data])

  useEffect(() => {
    if (variantInfoData?.variantInfo?.data && !variantInfoLoading) {
      const selected = variantInfoData?.variantInfo?.data
      const newItems = _.cloneDeep(items)
      newItems.splice(index, 1, {
        id: variantInfoData?.variantInfo?.data.id,
        stockQuantity: selected.inventoryOption?.totalStock - selected.inventoryOption?.soldStock,
        variantName: selected.variantName,
        discount: 0,
        count: item.count,
        type: selected.type,
      })
      setItems([...newItems])
    }
  }, [variantInfoData?.variantInfo?.data])

  const salePrice = price?.getSuggestionPrice?.data?.salesPrice ? price?.getSuggestionPrice?.data?.salesPrice : undefined
  if (!_.isNil(salePrice) && item?.price !== numeral(salePrice).format('0.00') && !manualPrice && !_.isNil(item?.id)) {
    setItems(items.map((val) => {
      if (val.id === item.id) {
        return { ...val, price: numeral(salePrice).format('0.00'), disabled: price?.getSuggestionPrice?.data?.type === 'PRICE_SET' }
      } return val
    }))
  }

  return (
    <Row key={item.id} type='flex' align='middle' gutter={16} style={{ marginTop: 16 }}>
      <Col span={8}>
        {
          index > 0
            ? <Icon color={theme.color.gray50} icon='fal fa-trash-alt' margin='0 16px 0 0' style={{ cursor: 'pointer' }} onClick={() => {
              setItems(_.remove(_.cloneDeep(items), (o) => !(o.count === item.count)))
            }} />
            : <Icon icon='none' margin='0 28.25px 0 0' style={{ cursor: 'pointer' }} />
        }
        <Select
          showSearch
          style={{ width: 'calc(100% - 28.25px)' }}
          filterOption={false}
          placeholder='ค้นหาสินค้า'
          loading={variantLoading}
          value={item.variantName}
          className={_.isUndefined(item.id) && 'state-error'}
          onSearch={(e) => {
            setSearch({ ...search, input: e })
            if (!_.isEmpty(e)) {
              getVariantList({ variables: { search: e } })
            }
          }}
          onSelect={(e) => {
            getVariantInfo({ variables: { id: e } })
            setSearch({ input: e, isNewList: false })
          }}
        >
          {
            (!variantLoading || !_.isEmpty(variantData)) && !_.isEmpty(search.input) && search.isNewList
            && variantData?.variantList?.data.map((u) => <Select.Option key={u.id} value={u.id}>{u.variantName}</Select.Option>)
          }
        </Select>
      </Col>
      <Col span={2} style={{ textAlign: 'end' }}>
        <Input
          rule={{ type: 'float' }}
          width='100%'
          placeholder='กรอกจำนวน'
          state={_.isUndefined(item.quantity) || !_.isNumber(item.quantity) || item.quantity <= 0 ? 'error' : 'default'}
          onChange={(e) => {
            const newItems = _.cloneDeep(items)
            newItems[index].quantity = parseFloat(e.target.value) || 0
            setItems([...newItems])
          }}
        />
      </Col>
      <Col span={4} style={{ textAlign: 'end' }}>
        <Input
          rule={{ type: 'float' }}
          width='100%'
          placeholder='กรอกราคา/หน่วย'
          disabled={item.disabled}
          value={item.price}
          state={_.isUndefined(item.price) || _.isNaN(parseFloat(item.price)) || item.price <= 0 ? 'error' : 'default'}
          onChange={(e) => {
            const newItems = _.cloneDeep(items)
            newItems[index].price = e.target.value
            setItems([...newItems])
            setManualPrice(true)
          }}
        />
      </Col>
      <Col span={4} style={{ textAlign: 'end' }}>
        <Input
          rule={{ type: 'float' }}
          width='100%'
          placeholder='กรอกส่วนลด/หน่วย'
          state={_.isNaN(parseFloat(item.price)) || item.price <= 0 ? 'error' : 'default'}
          onChange={(e) => {
            const newItems = _.cloneDeep(items)
            newItems[index].discount = parseFloat(e.target.value) || 0
            setItems([...newItems])
          }}
        />
      </Col>
      <Col span={3} style={{ textAlign: 'end' }}>
        <P>{numeral((item.price - (item.discount || 0)) * item.quantity || 0).format('0,0.00')}</P>
      </Col>
      <Col span={3} style={{ textAlign: 'center' }}>
        <P>{numeral(item.stockQuantity || 0).format('0,0.00')}</P>
      </Col>
    </Row>
  )
}

const Summary = ({
  mutationVariables, modal, setModal, chatChannelId, isEdit, quotationId, quotationInfo, noNeedCustomerId, locationState,
}) => {
  const history = useHistory()
  const { authorization } = useContext(GlobalContext)
  const [openAllSummary, setOpenAllSummary] = useState(false)
  const [createQuotation, { loading: mutationLoading }] = useMutation(CREATE_QUOTATION)
  const [doEditQuotation, { loading: updating }] = useMutation(EDIT_QUOTATION)
  const { data: bonusCommissionData } = useQuery(ORDER_BONUS_BY_ROLE, {
    variables: {
      roleId: mutationVariables?.commissionPerson?.roleId,
    },
    skip: isNil(mutationVariables?.commissionPerson?.roleId),
  })
  const toggleSummary = () => setOpenAllSummary(!openAllSummary)
  const containerProps = {
    position: modal ? 'absolute' : 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    transition: 'all 0.3s ease',
    height: openAllSummary ? '100%' : '126px',
    zIndex: 100,
  }
  const containerListProps = {
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: '0px 1px 14px 0px #00000017',
    transition: 'all 0.3s ease',
    height: openAllSummary ? 'auto' : '126px',
  }

  const getSumAll = (key, list) => reduce(list || mutationVariables?.products, (total, productDetail) => {
    if (productDetail?.productType === 'FREE') return total
    return total + (productDetail?.selectedProduct?.[key] * productDetail?.amount)
  }, 0)
  const getSum = (key) => sumBy(mutationVariables?.products, (productDetail) => {
    if (productDetail?.productType === 'FREE' && key !== 'amount') return 0
    return parseFloat(productDetail?.[key])
  })
  const callMutation = () => {
    const variables = {
      quotationId: isEdit ? quotationId : undefined,
      customerId: mutationVariables?.customerId,
      paymentType: 'CASH',
      channelId: isEdit
        ? undefined
        : chatChannelId,
      chatProviderId: mutationVariables?.chatProviderId,
      transferType: 'FULLAMOUNT',
      isCOD: isEdit ? undefined : mutationVariables?.paymentChannel === PAYMENT_CHANNEL?.COD?.key,
      lots: {
        id: isEdit ? quotationInfo?.quotationLots?.[0]?.id : undefined,
        lotIndex: isEdit ? undefined : 1,
        deliveryDate: mutationVariables?.deliveryDate,
        deliveryCost: parseFloat(mutationVariables?.deliveryCost) || 0,
        receiveType: isEdit ? undefined : mutationVariables?.receiveType,
        isTax: mutationVariables?.isTax || false,
        items: mutationVariables?.products?.map((pdDetail) => (
          {
            id: isEdit ? undefined : pdDetail?.selectedProduct?.id,
            quotationItemId: isEdit && isNumber(pdDetail?.id) ? pdDetail?.id : undefined,
            packId: isEdit ? pdDetail?.selectedProduct?.id : undefined,
            quantity: parseFloat(pdDetail?.amount),
            discount: parseFloat(pdDetail?.discount),
            price: parseFloat(pdDetail?.selectedProduct?.salePrice || 0),
            isFree: pdDetail?.productType === 'FREE',
          }
        )),
      },
      deliveryChannel: mutationVariables?.deliveryChannel,
      sellerId: mutationVariables?.commissionPerson?.id,
    }
    if (isEdit) {
      doEditQuotation({
        variables: {
          input: { ...omit(variables, ['customerId', 'paymentType', 'transferType', 'deliveryChannel']) },
        },
      }).then((resp) => showMessageMutation(resp?.data?.editQuotation, () => {
        if (modal) {
          setModal(false)
        } else {
          history.push({
            pathname: PATH?.quotation,
            state: {
              ...locationState,
            },
          })
        }
      }))
    } else {
      createQuotation({
        variables: {
          ...variables,
        },
      }).then((resp) => showMessageMutation(resp?.data?.createQuotation, () => {
        if (modal) {
          setModal(false)
        } else {
          history.push({
            pathname: PATH?.quotation,
            state: {
              ...locationState,
            },
          })
        }
      }))
    }
  }

  const isHavePermissionShowCost = authorization?.Quotation?.showCost
  const checkDisabledButton = () => updating || mutationLoading || isEmpty(mutationVariables?.paymentChannel) || isEmpty(mutationVariables?.receiveType) || isEmpty(mutationVariables?.deliveryDate) || isEmpty(mutationVariables?.products) || (!isEdit && !modal && !noNeedCustomerId && isNil(mutationVariables?.customerId))
  const totalCost = reduce(mutationVariables?.products, (total, productDetail) => total + (productDetail?.selectedProduct?.cost * productDetail?.amount), 0)
  const totalPrice = getSumAll('salePrice')
  const totalDiscount = reduce(mutationVariables?.products, (total, productDetail) => {
    if (productDetail?.productType === 'FREE') return total
    return total + (productDetail?.discount * productDetail?.amount)
  }, 0)
  const groupComm = groupBy(mutationVariables?.products, (obj) => obj?.sale)

  const adminSalesTotal = reduce(mutationVariables?.products, (total, productDetail) => total + ((productDetail?.selectedProduct?.salePrice - productDetail?.discount) * productDetail?.amount), 0)
  const bonusCommissionAdmin = last(sortBy(filter(bonusCommissionData?.orderBonusByRole?.data || [], (bonus) => bonus.tierPrice <= adminSalesTotal), 'tierPrice'))?.bonus
  const otherDetail = compact([
    isHavePermissionShowCost ? [
      { title: 'ต้นทุน', total: totalCost, totalColor: theme?.color?.gray100 },
      { title: 'กำไร', total: totalPrice - totalDiscount - totalCost, totalColor: theme?.color?.success },
      ...keys(groupComm)?.map((saleNameKey) => (
        {
          title: 'ค่าคอมฯ', subtitle: `(แอดมิน ${saleNameKey})`, total: getSumAll('com', groupComm?.[saleNameKey]), totalColor: theme?.color?.success,
        }
      )),
      { title: 'ค่าคอมฯโบนัส', total: bonusCommissionAdmin, totalColor: theme?.color?.success },
    ] : null,
    [
      { title: 'จำนวนสินค้า', total: `${getSum('amount')} ชิ้น`, totalColor: theme?.color?.gray100 },
      { title: 'รวมค่าสินค้า', total: totalPrice, totalColor: theme?.color?.gray100 },
      { title: 'ค่าขนส่ง', total: `฿ ${numeral(mutationVariables?.deliveryCost).format('0,0.00')}` },
      { title: 'ส่วนลด', total: totalDiscount, totalColor: theme?.color?.gray100 },
    ],
  ])

  const detail = (
    <>
      {
        otherDetail?.map((arr, i) => (
          <Div key={i} padding={i !== 0 ? '16px 0' : '0'} moreStyle={{ borderTop: i !== 0 ? `1px solid ${theme?.color?.gray10}` : 'none' }}>
            {
              arr?.map((rowDetail) => (
                <Div key={rowDetail?.title} display='flex' justifyContent='space-between' alignItems='center' margin='0 0 12px'>
                  <SmallText weightgray100>{rowDetail?.title}{rowDetail?.subtitle && <SmallText color={theme?.color?.gray40} margin='0 0 0 4px'>{rowDetail?.subtitle}</SmallText>}</SmallText>
                  <SmallText weightgray100 textAlign='right' color={rowDetail?.totalColor}>{isNumber(rowDetail?.total) ? `฿ ${numeral(rowDetail?.total)?.format('0,0.00')}` : rowDetail?.total}</SmallText>
                </Div>
              ))
            }
          </Div>
        ))
      }
    </>
  )

  return (
    <Div moreStyle={containerProps}>
      <span style={{
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, transition: 'opacity 0.3s ease', opacity: openAllSummary ? 1 : 0, backgroundColor: openAllSummary ? '#00000070' : 'transparent',
      }}></span>
      <Div bgColor={theme?.color?.offWhite} padding='16px' position='absolute' moreStyle={containerListProps}>
        {
          openAllSummary && detail
        }
        <Div display='flex' alignItems='center' justifyContent='space-between'>
          <P weightgray100>ยอดทั้งหมด</P>
          <Div display='flex' alignItems='center'>
            <P color={theme?.color?.primaryColor} weightgray100 padding='0 16px 0 0' margin='0 16px 0 0' style={{ borderRight: `1px solid ${theme?.color?.gray40}` }}>฿ {numeral(totalPrice + (parseFloat(mutationVariables?.deliveryCost) || 0) - totalDiscount).format('0,0.00')}</P>
            <IconButton color={theme?.color?.gray40} onlyIcon buttonStyle='rectangle' icon='fal fa-chevron-down' style={{
              height: 'auto', width: 'auto', padding: 0, border: 'none', borderRadius: 0, transform: openAllSummary ? 'rotate(180deg)' : 'none',
            }} onClick={toggleSummary} />
          </Div>
        </Div>
        <Div display='flex' margin='12px 0 16px'>
          <IconButton iconColor={theme?.color?.gray40} color={theme?.color?.gray10} inverse onlyIcon buttonStyle='rectangle' icon='fal fa-times' onClick={() => {
            if (modal) {
              setModal(false)
            } else {
              history.push(PATH.quotation)
            }
          }} />
          <Button disabled={checkDisabledButton()} loading={mutationLoading || updating} inverse margin='0 0 0 12px' width='calc(100% - 48px)' text={isEdit ? 'แก้ไขใบเสนอราคา' : 'สร้างใบเสนอราคา'} color={theme?.color?.success} onClick={callMutation} />
        </Div>
      </Div>
    </Div>
  )
}

const ProductSection = ({
  mutationVariables, setMutationVariables, drawerDetail, setDrawerDetail,
}) => {
  const [getUserData, { data: userData }] = useLazyQuery(USER_INFO)

  useEffect(() => {
    if (!isNil(getTokenData()?.userId)) {
      getUserData({
        variables: {
          id: getTokenData()?.userId,
        },
      })
    }
  }, [])

  const productList = mutationVariables?.products?.map((productDetail) => (
    {
      ...omit(productDetail, ['productType', 'selectedProduct']),
      ...productDetail?.selectedProduct,
      keyId: productDetail?.id,
      isFree: productDetail?.productType === 'FREE',
    }
  ))
  const openProductForm = () => setDrawerDetail({ ...drawerDetail, isShowProductForm: true })
  const closeProductForm = () => setDrawerDetail({ ...drawerDetail, isShowProductForm: false, editId: null })
  const setProduct = (selectedProduct) => {
    const findProduct = find(mutationVariables?.products, (productDetail) => productDetail?.id === selectedProduct?.id)
    if (!isEmpty(findProduct)) {
      const newList = mutationVariables?.products?.map((productDetail) => {
        if (productDetail?.id === selectedProduct?.id) return selectedProduct
        return productDetail
      })
      setMutationVariables({ ...mutationVariables, products: newList })
    } else {
      setMutationVariables({ ...mutationVariables, products: [...(mutationVariables?.products || []), selectedProduct] })
    }
  }
  const removeSelectedProduct = (removeId) => setMutationVariables({
    ...mutationVariables,
    products: compact(mutationVariables?.products?.map((productDetail) => {
      if (productDetail?.id !== removeId) return productDetail
      return null
    })),
  })

  const drawer = (
    <Drawer
      placement='right'
      closable={false}
      width='100%'
      visible={drawerDetail?.isShowProductForm}
      bodyStyle={{ padding: 0, height: '100vh' }}
      style={{ transform: 'translateX(0) !important' }}
    >
      <ProductForm
        closeProductForm={closeProductForm}
        onSelect={setProduct}
        isEdit={!isNil(drawerDetail?.editId)}
        selectedData={find(mutationVariables?.products, (productDetail) => productDetail?.id === drawerDetail?.editId)}
        userData={userData}
        sellerData={mutationVariables?.commissionPerson}
      />
    </Drawer>
  )

  return (
    <Div margin='16px -16px 0' padding='16px 16px 0' moreStyle={{ borderTop: `1px solid ${theme?.color?.gray40}` }}>
      { drawerDetail?.isShowProductForm && drawer }
      <P weightgray100 color={theme?.color?.primaryColor}>รายการสินค้า</P>
      <SmallText margin='0 0 0 6px'>({numeral(productList?.length).format('0,0')} รายการ)</SmallText>
      {
        productList?.map((pdDetail) => {
          const totalPrice = (pdDetail?.salePrice * pdDetail?.amount) - (pdDetail?.discount * pdDetail?.amount)
          const totalCost = pdDetail?.cost * pdDetail?.amount
          return (
            <ProductDisplay
              key={pdDetail?.id}
              pdDetail={pdDetail}
              totalPrice={totalPrice}
              totalCost={totalCost}
              setDrawerDetail={setDrawerDetail}
              removeSelectedProduct={removeSelectedProduct}
              displayOnly={pdDetail?.sale !== `${userData?.userDetail?.data?.firstName} ${userData?.userDetail?.data?.lastName}`}
            />
          )
        })
      }
      <DashedButton fullWidth margin='16px 0 0' icon='fal fa-plus' text='เพิ่มสินค้า / บริการ' onClick={openProductForm} />
    </Div>
  )
}

export const QuatationForm = ({
  modal, contactAddressId, customerId, setModal, channel, customerName, isEdit, chatChannelId, channelId, chatOwner, chatProviderId,
}) => {
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const userTokenData = getTokenData()
  const { authorization } = useContext(GlobalContext)
  const { navigateDetail, updateNavigateDetail } = useContext(GlobalContext)
  const [mutationVariables, setMutationVariables] = useState({
    chatProviderId: chatProviderId || location?.state?.chatProviderId,
    deliveryDate: moment(),
    channel: channelId,
    paymentChannel: !isNil(chatChannelId) ? PAYMENT_CHANNEL?.COD?.key : undefined,
    receiveType: !isNil(chatChannelId) ? DELIVERY_CHANNEL?.TRANSFER?.key : undefined,
    commissionPerson: isEmpty(chatOwner)
      ? { id: userTokenData?.userId, name: userTokenData?.fullname, roleId: first(userTokenData.roles)?.id }
      : { id: chatOwner?.id, name: chatOwner?.name, roleId: chatOwner?.roleId },
  })
  const [customer, setCustomer] = useState()
  const { data: quatationNumberData, loading: quatationNumberLoading } = useQuery(NEXT_QUOTATION_NUMBER, { skip: isEdit })
  const { data: defaultDeliveryChannelData } = useQuery(DEFAULT_DELIVERY_CHANNEL, { skip: isEdit })
  const [getCustomers, {
    loading: customerLoading, data: customerData,
  }] = useLazyQuery(CUSTOMER_LIST)
  const [getQuotationInfo, { loading: quotationInfoLoading, data: quotationData }] = useLazyQuery(QUOTATION_DETAIL)
  const [getProvider, { loading: providerLoading, data: providerData }] = useLazyQuery(PROVIDER_LIST)
  const [getUserList, { loading: userListLoading, data: userListData }] = useLazyQuery(USER_LIST)
  const [drawerDetail, setDrawerDetail] = useState({
    isShowProductForm: false,
    editId: null,
  })

  const callUpdateNavigat = () => {
    updateNavigateDetail({
      ...navigateDetail,
      pageTitle: isEdit ? 'การแก้ไขใบเสนอราคา' : 'การสร้างใบเสนอราคา',
      leftIcon: 'fal fa-chevron-left',
      leftFn: () => history.push({
        pathname: PATH?.quotation,
        state: {
          ...location?.state,
        },
      }),
    })
  }

  useEffect(() => {
    if (!isEmpty(quotationData?.quotationInfo?.data)) {
      const getData = quotationData?.quotationInfo?.data
      setMutationVariables({
        chatProviderId: getData?.chatChannel?.chatProvider?.id || undefined,
        customerId: getData?.customerId,
        paymentChannel: getData?.isCOD ? PAYMENT_CHANNEL?.COD?.key : PAYMENT_CHANNEL?.FULLAMOUNT?.key,
        deliveryDate: isEmpty(getData?.quotationLots?.[0]?.deliveryDate) ? '-' : dayjs(getData?.quotationLots?.[0]?.deliveryDate),
        deliveryCost: getData?.quotationLots?.[0]?.deliveryCost,
        deliveryChannel: getData?.deliveryChannel,
        receiveType: getData?.quotationLots?.[0]?.receiveType,
        isTax: getData?.quotationLots?.[0]?.isTax,
        products: getData?.quotationLots?.[0]?.quotationItems?.map((quotationItemDetail) => (
          {
            id: quotationItemDetail?.id,
            amount: quotationItemDetail?.quantity,
            discount: quotationItemDetail?.discount,
            productType: quotationItemDetail?.isFree ? 'FREE' : 'PRODUCT',
            sale: isEmpty(getData?.createdByUser) ? '-' : `${getData?.createdByUser?.firstName} ${getData?.createdByUser?.lastName}`,
            selectedProduct: {
              id: !isNil(quotationItemDetail?.packId) ? `_pac#${quotationItemDetail?.packId}` : `_set#${quotationItemDetail?.setId}`,
              img: quotationItemDetail?.pack?.inventoryOption?.files?.[0]?.url || quotationItemDetail?.set?.file?.url,
              name: quotationItemDetail?.pack?.variantName || quotationItemDetail?.set?.name,
              cost: quotationItemDetail?.cost,
              com: quotationItemDetail?.commission,
              salePrice: (quotationItemDetail?.totalPrice / quotationItemDetail?.quantity) + quotationItemDetail?.discount,
            },
          }
        )),
        commissionPerson: isEmpty(getData?.seller)
          ? {}
          : { id: getData?.seller?.id, name: `${getData?.seller?.firstName} ${getData?.seller?.lastName}`, roleId: first(getData?.seller?.roles)?.id },
      })
      setCustomer(getData?.customer)
    }
  }, [quotationData])

  useEffect(() => {
    if (!isEmpty(params?.id)) {
      getQuotationInfo({
        variables: {
          id: parseInt(params?.id),
        },
      })
    }
  }, [params?.id])

  useEffect(() => {
    if (!isNil(location?.state?.customerId) || !isNil(location?.state?.channelId)) {
      setMutationVariables({
        ...mutationVariables,
        channel: location?.state?.channel,
        channelId: location?.state?.channelId,
        customerId: location?.state?.customerId,
      })
    }
  }, [location?.state?.customerId, location?.state?.channelId])

  useEffect(() => {
    if (defaultDeliveryChannelData) {
      setMutationVariables({
        ...mutationVariables,
        deliveryChannel: find(defaultDeliveryChannelData?.defaultDeliveryChannel?.data, (o) => o.isSet)?.deliveryChannel,
      })
    }
  }, [defaultDeliveryChannelData])

  useEffect(() => {
    callUpdateNavigat()
  }, [drawerDetail?.isShowProductForm])

  useEffect(() => {
    if (customerId && !_.isEmpty(customerData)) {
      const selected = _.find(customerData?.customerList?.data?.list, (o) => o.id === customerId)
      setMutationVariables({
        ...mutationVariables,
        customerId,
        contactAddressId,
        channel: channel || channelId,
        paymentType: selected?.paymentType,
      })
      setCustomer(selected)
    }
  }, [contactAddressId, customerId, customerData])

  useEffect(() => {
    if (!isEmpty(customerName)) setCustomer({ ...customer, name: customerName })
  }, [customerName])

  useEffect(() => {
    if (modal) {
      setMutationVariables({
        ...mutationVariables,
        channel: channel || channelId,
        customerId,
      })
    }
  }, [modal])

  useEffect(() => {
    getProvider({
      variables: {
        isCrm: true,
        isQuotation: true,
      },
    })
    if (!isNil(location?.state?.customerId) && isEmpty(location?.state?.customerId)) {
      getCustomers({
        variables: {
          name: location?.state?.customerId,
          limit: 1,
          offset: 0,
        },
      })
    }
  }, [])

  const getVariantInfo = async (infoId) => {
    const { data } = await client.query({
      query: VARIANT_INFO_FOR_QUOTATION,
      variables: {
        id: `${infoId}`,
        sellerId: mutationVariables?.commissionPerson?.id,
      },
    })
    return data
  }

  const mapUpdateValue = () => map(mutationVariables?.products, async (product) => {
    const getData = await getVariantInfo(product?.selectedProduct?.id)

    return {
      ...product,
      selectedProduct: {
        ...product?.selectedProduct,
        com: getData?.variantInfo?.data?.commission,
      },
    }
  })

  const getNewUpdatedList = async () => {
    const newList = await Promise.all(mapUpdateValue())
    setMutationVariables({ ...mutationVariables, products: newList })
  }

  useEffect(() => {
    if (!isEmpty(mutationVariables?.commissionPerson) && !isEmpty(mutationVariables?.products)) {
      getNewUpdatedList()
    }
  }, [mutationVariables?.commissionPerson])

  const callGetUserList = (searchValue) => {
    getUserList({
      variables: {
        name: searchValue,
      },
    })
  }

  const chatOwnerName = !isEmpty(quotationData?.quotationInfo?.data?.chatOwner) ? `${quotationData?.quotationInfo?.data?.chatOwner?.firstName} ${quotationData?.quotationInfo?.data?.chatOwner?.lastName}` : chatOwner?.name
  const isNotMatchSellerAndOwner = !isEmpty(chatOwnerName) && chatOwnerName !== mutationVariables?.commissionPerson?.name

  const selectCommissionPersonList = _.map(userListData?.userList?.data?.list, (user) => ({ roleId: first(user.roles).id, value: user?.id, text: `${user?.firstName} ${user?.lastName}` })) || []
  const selectCommissionPersonProps = {
    className: 'select-with-bg',
    showSearch: true,
    style: { width: '100%', color: isNotMatchSellerAndOwner ? theme?.color?.error : theme?.color?.gray100 },
    filterOption: false,
    placeholder: 'ค้นหาคนรับค่าคอมจากชื่อ',
    loading: userListLoading,
    value: mutationVariables?.commissionPerson?.name,
    onSearch: (e) => delayInput(() => callGetUserList(e)),
    onSelect: (selectedValue) => {
      setMutationVariables({
        ...mutationVariables,
        commissionPerson: {
          id: selectedValue,
          name: find(selectCommissionPersonList, (user) => user?.value === selectedValue)?.text,
          roleId: find(selectCommissionPersonList, (user) => user?.value === selectedValue)?.roleId,
        },
      })
    },
    disabled: !authorization?.Quotation?.editSeller || (isEdit && !_.includes([QUOTATION_STATUS?.[0]?.value, QUOTATION_STATUS?.[1]?.value], quotationData?.quotationInfo?.data?.status)),
  }

  if (quotationInfoLoading) return <FullPageLoading height='calc(100vh - 53px)' fontSize={theme?.fonts?.size?.subTitle} />

  return (
    <Div margin='16px' padding='0 0 150px' style={{ background: '#FFFFFF' }}>
      <P weightgray100 display='block' margin='0 0 8px' color={theme.color.primaryColor}>ใบเสนอราคา {isEdit ? quotationData?.quotationInfo?.data?.refId : !quatationNumberLoading && quatationNumberData?.nextQuotationNumber}</P>
      <Div display='flex' justifyContent='space-between'>
        <SmallText weightgray100 color={theme?.color?.gray40}>วันที่สั่งซื้อ {dayjs().format('DD/MM/YYYY')}</SmallText>
        <SmallText weightgray100 color={theme?.color?.gray40}>เจ้าของแชต: <SmallText color={isNotMatchSellerAndOwner ? theme?.color?.error : theme?.color?.gray40}>{chatOwnerName || '-'}</SmallText></SmallText>
      </Div>
      <Div margin='24px 0 0' display='flex' justifyContent='space-between'>
        <Div width='calc((100% - 16px) / 2)'>
          <SmallText display='block' color={theme?.color?.gray40} margin='0 0 8px'>ช่องทาง</SmallText>
          <Select
            className='select-with-bg'
            placeholder='เลือกช่องทาง'
            style={{ width: '100%' }}
            loading={providerLoading}
            value={mutationVariables?.chatProviderId}
            showSearch
            onSelect={(value) => setMutationVariables({ ...mutationVariables, chatProviderId: value })}
            disabled={isEdit}
          >
            {
              providerData?.providerList?.data?.list?.map((providerDetail) => (
                <Select.Option key={providerDetail?.id} value={providerDetail?.id}>{providerDetail?.name}</Select.Option>
              ))
            }
          </Select>
        </Div>
        <Div width='calc((100% - 16px) / 2)'>
          <SmallText display='block' color={theme?.color?.gray40} margin='0 0 8px'>ลูกค้า</SmallText>
          <Select
            className='select-with-bg'
            showSearch
            style={{ width: '100%' }}
            filterOption={false}
            placeholder='ค้นหาลูกค้าจากชื่อ'
            onSearch={(e) => delayInput(() => {
              if (!isEmpty(e) && !isNil(e)) {
                getCustomers({ variables: { name: e, limit: 10, offset: 0 } })
              }
            })}
            loading={customerLoading}
            value={customer?.name || mutationVariables?.customerId}
            disabled={modal || isEdit}
            onSelect={(e) => {
              const selected = _.find(customerData?.customerList?.data?.list, (o) => o.id === e)
              setMutationVariables({
                ...mutationVariables,
                customerId: e,
                paymentType: selected.paymentType,
                contactAddressId: undefined,
              })
              setCustomer(selected)
            }}
          >
            {
              (!customerLoading || !_.isEmpty(customerData))
              && customerData?.customerList?.data?.list?.map((v) => <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>)
            }
          </Select>
        </Div>
      </Div>
      <Div margin='16px 0 0'>
        <SmallText display='block' color={theme?.color?.gray40} margin='0 0 8px'>แอดมินที่ได้รับค่าคอมฯ</SmallText>
        <Select {...selectCommissionPersonProps}>
          {
            _.map(selectCommissionPersonList, (user) => (
              <Select.Option key={user?.value} value={user?.value}>{user?.text}</Select.Option>
            ))
          }
        </Select>
      </Div>
      <ProductSection drawerDetail={drawerDetail} setDrawerDetail={setDrawerDetail} mutationVariables={mutationVariables} setMutationVariables={setMutationVariables} />
      <Div margin='32px 0 0'>
        <Div>
          <SmallText display='block' color={theme?.color?.gray40} margin='0 0 8px'>ช่องทางการชำระเงิน</SmallText>
          <Select
            className='select-with-bg'
            placeholder='เลือกช่องทาง'
            style={{ width: '100%' }}
            value={PAYMENT_CHANNEL?.[mutationVariables?.paymentChannel]?.text}
            onSelect={(value) => setMutationVariables({ ...mutationVariables, paymentChannel: value })}
            disabled={isEdit}
          >
            {
              keys(PAYMENT_CHANNEL)?.map((paymentKey) => (
                <Select.Option key={paymentKey} value={paymentKey}>{PAYMENT_CHANNEL?.[paymentKey]?.text}</Select.Option>
              ))
            }
          </Select>
        </Div>
        <Div display='flex' justifyContent='space-between' margin='16px 0 0' >
          <Div width='calc((100% - 16px) / 2)'>
            <SmallText display='block' color={theme?.color?.gray40} margin='0 0 8px'>วันที่ส่ง</SmallText>
            <DatePicker inputReadOnly={true} className='date-picker-with-bg input' format='DD/MM/YYYY' placeholder='เลือกวันที่' style={{ width: '100%' }} value={mutationVariables?.deliveryDate} onChange={(selectedDate) => setMutationVariables({ ...mutationVariables, deliveryDate: selectedDate })} />
          </Div>
          <Div width='calc((100% - 16px) / 2)'>
            <SmallText display='block' color={theme?.color?.gray40} margin='0 0 8px'>ช่องทางการจัดส่ง</SmallText>
            <Select
              className='select-with-bg'
              placeholder='เลือกช่องทาง'
              style={{ width: '100%' }}
              value={DELIVERY_CHANNEL?.[mutationVariables?.receiveType]?.text}
              onSelect={(value) => setMutationVariables({ ...mutationVariables, receiveType: value })}
              disabled={isEdit}
            >
              {
                keys(DELIVERY_CHANNEL)?.map((deliveryKey) => (
                  <Select.Option key={deliveryKey} value={deliveryKey}>{DELIVERY_CHANNEL?.[deliveryKey]?.text}</Select.Option>
                ))
              }
            </Select>
          </Div>
        </Div>
        <Div display='flex' justifyContent='space-between' margin='16px 0 0' >
          <Div width='calc((100% - 16px) / 2)'>
            <SmallText display='block' color={theme?.color?.gray40} margin='0 0 8px'>ค่าจัดส่ง</SmallText>
            <Input
              rule={{ type: 'float' }}
              width='100%'
              placeholder='กรอกค่าจัดส่ง'
              value={mutationVariables?.deliveryCost}
              onChange={(event) => setMutationVariables({ ...mutationVariables, deliveryCost: event.target.value })}
            />
          </Div>
          <Div width='calc((100% - 16px) / 2)'>
            <SmallText display='block' color={theme?.color?.gray40} margin='0 0 8px'>บริษัทจัดส่ง</SmallText>
            <Select
              className='select-with-bg'
              placeholder='เลือกบริษัทจัดส่ง'
              style={{ width: '100%' }}
              value={DELIVERY_CHANNEL_PROVIDER?.[mutationVariables?.deliveryChannel]?.LABEL}
              onSelect={(value) => setMutationVariables({ ...mutationVariables, deliveryChannel: value })}
              disabled={isEdit}
            >
              {
                keys(DELIVERY_CHANNEL_PROVIDER)?.map((deliveryKey) => (
                  <Select.Option key={DELIVERY_CHANNEL_PROVIDER[deliveryKey].KEY} value={DELIVERY_CHANNEL_PROVIDER[deliveryKey].KEY}>{DELIVERY_CHANNEL_PROVIDER?.[deliveryKey]?.LABEL}</Select.Option>
                ))
              }
            </Select>
          </Div>
        </Div>
        <Div padding='24px 0 0'>
          <CheckboxAntd checked={mutationVariables?.isTax} onChange={(e) => setMutationVariables({ ...mutationVariables, isTax: e.target.checked })}><SmallText>ต้องการรับใบกำกับภาษี</SmallText></CheckboxAntd>
        </Div>
      </Div>
      <Summary
        isEdit={isEdit}
        quotationInfo={quotationData?.quotationInfo?.data}
        quotationId={parseInt(params?.id)}
        mutationVariables={mutationVariables}
        modal={modal}
        noNeedCustomerId={location?.state?.noNeedCustomerId}
        setModal={setModal}
        chatChannelId={chatChannelId || location?.state?.chatChannelId}
        locationState={location?.state}
      />
    </Div>
  )
}

export default QuatationForm
